import { getDocxImage } from '@/components/dueDiligence-page/DDR-DOCX/utils';
import * as docx from 'docx';
import { IDocumentFieldAnswerAttachment, IDocumentRecordFieldEntity } from '../../types';
import { aiFieldValueMu, fieldValueMu } from '../managerUpdatePDF/utils';
import { getAiSuggestedSection } from './utils';

export const getOneColumnSection = async (fields: IDocumentRecordFieldEntity[], includeAiSuggested: boolean, bodyFontColor: string) => {
  const maxImageWidth = 665;
  const docxData: (docx.Paragraph | docx.Table)[] = [];

  const createParagraph = (text: string, bold: boolean, color: string, beforeSpacing: number, afterSpacing: number) => {
    const formattedText = text?.split('\n').flatMap((line, index, array) => {
      return index < array.length - 1
        ? [
            new docx.TextRun({
              text: line,
              bold,
              color
            }),
            new docx.TextRun({ break: 1 })
          ]
        : new docx.TextRun({
            text: line,
            bold,
            color
          });
    });

    return new docx.Paragraph({
      children: formattedText,
      spacing: { before: beforeSpacing, after: afterSpacing }
    });
  };

  const getFieldData = async (field: IDocumentRecordFieldEntity) => {
    const fieldData = [];
    const { attachments, value, commentary } = field;
    const hasIncludedAttachments = attachments.some((attachment) => attachment.includeInAutomatedContent);
    const isMatchedWithAI = field.value === null && field.commentary === null && field.AI?.answers.length && includeAiSuggested;

    if (value || commentary || hasIncludedAttachments) {
      if (fields.length > 1) {
        fieldData.push(createParagraph(field.documentField.name, true, bodyFontColor, 300, 100));
      }
      fieldData.push(createParagraph(fieldValueMu(field.documentField.type, value, commentary), false, bodyFontColor, 0, 0));
    }

    if (isMatchedWithAI) {
      if (fields.length > 1) {
        fieldData.push(createParagraph(`* ${field.documentField.name}`, true, bodyFontColor, 300, 100));
      }
      fieldData.push(createParagraph(aiFieldValueMu(field.documentField.type, field.AI.answers[0]), false, bodyFontColor, 0, 0));
      const aiSuggestedSection = await getAiSuggestedSection(fields, bodyFontColor);
      fieldData.push(...aiSuggestedSection);
    }

    return fieldData;
  };

  const getImageData = async (attachment: IDocumentFieldAnswerAttachment) => {
    if (!attachment.includeInAutomatedContent) return;

    const imageData = await getDocxImage(attachment.secure_blob_storage_asset.url, attachment.secure_blob_storage_asset.assetKey);
    if (!imageData) return;

    const img = new Image();
    img.src = URL.createObjectURL(new Blob([imageData]));
    await new Promise((resolve) => (img.onload = resolve));

    let dimensions = { width: img.naturalWidth, height: img.naturalHeight };
    if (dimensions.width > maxImageWidth) {
      const aspectRatio = dimensions.height / dimensions.width;
      dimensions = { width: maxImageWidth, height: Math.round(maxImageWidth * aspectRatio) };
    }

    return new docx.Paragraph({
      children: [
        new docx.ImageRun({
          data: imageData,
          transformation: dimensions
        })
      ],
      spacing: { before: 200, after: 200 }
    });
  };

  for await (const field of fields) {
    const fieldData = await getFieldData(field);
    docxData.push(...fieldData);

    const imagesData = await Promise.all(field.attachments.map(getImageData));
    const validImagesData = imagesData.filter((item): item is docx.Paragraph => item !== undefined);
    docxData.push(...validImagesData);
  }

  return docxData;
};
